/* Swiper Overrides */
:root {
    --swiper-theme-color: #003359 !important;
    --swiper-pagination-bottom: 0px;
    --swiper-pagination-bullet-inactive-color: #99ADBD !important;
    --swiper-pagination-bullet-inactive-opacity: 1 !important;
}

html {
    overscroll-behavior: none;
    background-color: rgba(193, 200, 212, 0.2)
}

body,
body>div:not(.smartbanner):first-child,
#__next {
    height: 100%;
}

body {
    font-family: '"Work Sans", "Roboto", Montserrat';
}

input,
textarea {
    outline: none;
}

.smartbanner {
    position: fixed;
    top: 0;
    left: unset;
    z-index: 1200;
}